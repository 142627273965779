import { Token } from '@stripe/stripe-js';
import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useAccountSettings from 'common/hooks/useAccountSettings';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { DATA_TEST_WEIGHT_MANAGEMENT } from 'common/utils/constants/dataTest';
import { hasDiscountWmLite } from 'common/utils/flags';
import { logError } from 'common/utils/helpers';
import { onWeightCareConversionTrack } from 'common/utils/trackers';
import {
  PROGRAM_TYPE,
  ProgramSlugToPlanType,
  ProgramType,
  ProgramsAtCheckout,
} from 'common/utils/types';
import { isUserInWMLite, isUserMemberOfProgram } from 'common/utils/user';
import { IntakeContext } from 'components/Intake/context';
import { StripePaymentIntentError } from 'components/Intake/errors';
import { handleErrorNotification } from 'components/Intake/helpers';
import { BundleOrderStates } from 'components/Intake/types';
import {
  ClientError,
  NotificationContext,
  PaymentServerErrors,
} from 'components/Notification/NotificationContext';
import { useStripeFormContext } from 'components/StripeComponent/Providers/StripeFormProvider';
import { useCastPaymentErrorToNotificationData } from 'components/StripeComponent/utils';
import ToastNotification from 'components/ToastNotification';
import NOTIFICATIONS from 'components/ToastNotification/constants';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import PaymentDescription from 'pages/CreditCardForm/components/Payment/PaymentDescription';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import COPY_CONTENT from '../Payment.content';
import * as S from '../Payment.styles';
import LoadingAnimation from './LoadingAnimation';
import PaymentDetails from './PaymentDetails';
import VariantSelector from './VariantSelector/VariantSelector';

type FormValues = {
  cardNumber: string;
  expirationDate: string;
  cvcNumber: string;
  zipCode: string;
};

type PaymentInfoProps = {
  program: string;
};

// const programsWithDiscount: ProgramType[] = [
//   PROGRAM_TYPE['weight-management-full'],
// ];

const programsBlockedInNy: ProgramType[] = [PROGRAM_TYPE['everlywell-plus']];

const PaymentInfo: ({ program }: PaymentInfoProps) => JSX.Element = ({
  program = PROGRAM_TYPE['weight-management-full'],
}) => {
  const hasDiscountWmLiteActive = hasDiscountWmLite();
  // const hasMultipleVariantsFeature = showMultipleVariantsWcPlus();
  // const hasWeightCarePlusPromo =
  //   hasWeightCarePromo() &&
  //   programsWithDiscount.includes(program as ProgramType);
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  const extoleShareableCode = searchParams.get('extole_shareable_code');
  const isNyBlocked = programsBlockedInNy.includes(program as ProgramType);

  const {
    orderState,
    paymentIntent,
    setIntakeComplete,
    updateBundleOrderState,
  } = useContext(IntakeContext);
  const { setNotificationData } = useContext(NotificationContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentError, setPaymentError] = useState<ClientError | undefined>();
  const [content, setContent] = useState(
    COPY_CONTENT[program as ProgramsAtCheckout] ||
      COPY_CONTENT['weight-management-full'],
  );
  const [variantId, setVariantId] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasMultipleVariants, setHasMultipleVariants] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [intervalPrice, setIntervalPrice] = useState<string | undefined>();

  const {
    checkStripeElementsOnError,
    elements,
    fetchToken,
    stripe,
    stripeElementsErrors,
  } = useStripeFormContext();

  const [hasAgreedToLegalTerms, setHasAgreedToLegalTerms] =
    useState<Boolean>(false);
  const [isMarketingOptInChecked, setIsMarketingOptInChecked] =
    useState<boolean>();

  const { createMembershipBilling } = useAccountSettings();

  const navigate = useNavigate();

  const useFormMethods = useForm<FormValues>({
    mode: 'onBlur',
  });
  const { handleSubmit, register, errors } = useFormMethods;
  const isSubmitDisabled =
    !isEmpty(errors) ||
    !isEmpty(stripeElementsErrors) ||
    !hasAgreedToLegalTerms ||
    isLoading;

  const notificationData = useCastPaymentErrorToNotificationData(paymentError);

  const { data: userData, isLoading: isLoadingUserData } = useUser({
    onSuccess: (userResponse) => {
      if (userResponse.data) {
        const isWMLiteMember = isUserInWMLite(userResponse.data);
        const isWMLiteUserFlow =
          isWMLiteMember &&
          hasDiscountWmLiteActive &&
          program === PROGRAM_TYPE['weight-management-full'];

        /**
         * Existing WM Lite user in WC+ checkout flow
         */
        if (isWMLiteUserFlow) {
          setContent(COPY_CONTENT['weight-management']);
        }
        /**
         * If none of the above scenarios,
         * will show "default" content for a new user in WC+ checkout flow
         */
      }
    },
  });

  const { data: telehealthAppointments, isLoading: isLoadingAppointments } =
    useTelehealthAppointments({
      limit: 1,
    });

  const onPlaceOrder = async (formData: FormValues) => {
    if (!variantId) {
      logError('Payment Info Intake - Missing variantId', {
        method: 'onPlaceOrder - Confirm your membership',
      });
      return;
    }

    setIsLoading(true);
    setPaymentError(undefined);
    const isSubmitEnabled = !isSubmitDisabled;

    if (isSubmitEnabled) {
      try {
        // Track button click
        await analytics.track({
          event: ANALYTICS.EVENTS.CLICKED_BUTTON,
          data: {
            label: ANALYTICS.LABELS.WEIGHT_CARE.ENROLLMENT.PAYMENT_INFO,
          },
        });
        const noElements = !elements;
        if (noElements) {
          return;
        }
        const token: Token = await fetchToken(formData);

        const campaignId = Cookies.get('iterableEmailCampaignId');

        // Create membership billing - save payment data
        await createMembershipBilling({
          token: token.id,
          wantsMarketing: !!isMarketingOptInChecked,
          variantId,
          ...(campaignId && { marketingCampaignId: Number(campaignId) }),
          ...(extoleShareableCode && { extoleShareableCode }),
        });

        updateBundleOrderState(BundleOrderStates.COMPLETE);

        if (userData?.data) {
          onWeightCareConversionTrack({
            program,
            email: userData.data.email,
            userId: userData.data.id,
          });
        }
      } catch (err) {
        const error = err as ClientError;
        setPaymentError({
          message: error.message,
          type: PaymentServerErrors.PaymentProcessingError,
          status: error?.status,
        });

        if (error.message) {
          logError(error.message, {
            method: 'Payment Info Intake - Confirm your membership',
            ...error,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (paymentIntent) {
      if (!stripe || !elements) {
        handleErrorNotification(
          new StripePaymentIntentError(
            'There was an error completing the payment',
            false,
          ),
          setNotificationData,
          NOTIFICATIONS.INTAKE_COMPLETE_STORE_FAILURE('CareIntakePayment'),
        );
        return;
      }
    }
  }, [elements, paymentIntent, setNotificationData, stripe]);

  useEffect(() => {
    if (orderState && orderState === BundleOrderStates.COMPLETE) {
      setIsLoading(false);
      setNotificationData(
        NOTIFICATIONS.INTAKE_PAYMENT_COMPLETE('orderCompletion'),
      );
      setIntakeComplete && setIntakeComplete(true);
    }
  }, [orderState, setIntakeComplete, setNotificationData]);

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.VIRTUAL_CARE_INTAKE,
        program,
      },
    });
  }, [program]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'paymentInfoComponent',
        programName: program,
      },
    });
  }, [program]);

  const onMarketingOptInChange = useCallback(setIsMarketingOptInChecked, [
    setIsMarketingOptInChecked,
  ]);

  useEffect(() => {
    if (isLoadingAppointments || isLoadingUserData) {
      return;
    }
    const user = userData?.data;

    const hasAppointments = !isEmpty(telehealthAppointments?.data ?? []);

    const planType = ProgramSlugToPlanType[program];
    const isUserActive = !!user && isUserMemberOfProgram(user, [planType]);
    const isWCPlusActiveMember =
      isUserActive && planType === 'weight_management';

    // If the user is a WC+ member and has appointments, redirect to virtual care
    if (isWCPlusActiveMember && hasAppointments) {
      navigate('/virtual-care');
      return;
    }

    // If the user is a WC+ member and has no appointments, redirect to healthie
    if (isWCPlusActiveMember) {
      navigate(
        `/telehealth/sessions/?program=${PROGRAM_TYPE['weight-management-full']}`,
      );
      return;
    }

    // If the user is active in the program they are trying to enroll in, redirect to dashboard
    if (isUserActive) {
      navigate('/dashboard');
      return;
    }

    setIsLoading(false);
  }, [
    isLoadingAppointments,
    isLoadingUserData,
    navigate,
    program,
    telehealthAppointments,
    userData?.data,
  ]);

  const isWMLiteUser = !!userData?.data && isUserInWMLite(userData?.data);
  /**
   * TODO: Commenting to avoid showing just
   * one variant price on WC+
   */

  // const hasOneVariantOrIsWMLite =
  //   isWMLiteUser || !hasMultipleVariants || !hasMultipleVariantsFeature;

  // const getIntervalPrice = (): string => {
  //   if (hasWeightCarePlusPromo && isWMLiteUser) {
  //     return content?.PRICE_DISCOUNT || '';
  //   }

  //   if (intervalPrice) {
  //     return intervalPrice;
  //   }

  //   return content.PRICE || '';
  // };

  const hasAgreedToLegalTermsChange = useCallback(setHasAgreedToLegalTerms, [
    setHasAgreedToLegalTerms,
  ]);
  const hasVariantsCallback = useCallback(setHasMultipleVariants, [
    setHasMultipleVariants,
  ]);

  return (
    <S.Container>
      <LoadingAnimation isSubmitting={isLoading} />
      <form onSubmit={handleSubmit(onPlaceOrder, checkStripeElementsOnError)}>
        {notificationData && (
          <S.ToastWrapper>
            <ToastNotification notification={notificationData} />
          </S.ToastWrapper>
        )}
        <PaymentDescription
          title={content.TITLE}
          titleAlign={'center'}
          acceptedCards
        >
          <VariantSelector
            slug={program}
            onSelect={(id) => setVariantId(id)}
            hasVariantsCallback={hasVariantsCallback}
            isWMLiteUser={isWMLiteUser}
            planSlug={plan}
            setIntervalPrice={(price) => setIntervalPrice(price)}
          ></VariantSelector>

          <S.Cards>
            <S.Card>
              {/* TODO: Commenting to avoid showing just
              one variant price on WC+ */}
              {/* {hasOneVariantOrIsWMLite && getIntervalPrice() ? (
                <>
                  {getIntervalPrice()
                    .split('\n')
                    .map((str) => (
                      <S.Price>{str}</S.Price>
                    ))}
                  <S.HorizontalLine />
                </>
              ) : null} */}
              <S.PerksContainer>
                <S.PerksSubtitle align="left">
                  {content.PERKS_SUBTITLE}
                </S.PerksSubtitle>
                <S.Description align="left">
                  <S.List>
                    {content.PERKS.map((perk) => {
                      if (
                        perk.includes(
                          'Lifestyle recommendations and tracking in our app',
                        )
                      ) {
                        return (
                          <S.Item key={perk}>
                            <S.Icon />
                            <S.Meta>
                              Lifestyle recommendations and tracking in our{' '}
                              <S.Link
                                href="https://www.everlywell.com/download-app/"
                                target="_blank"
                              >
                                app
                              </S.Link>
                            </S.Meta>
                          </S.Item>
                        );
                      }
                      if (
                        perk.includes('Access to continual at-home lab testing')
                      ) {
                        return (
                          <S.Item key={perk}>
                            <S.Icon />
                            <S.Meta>
                              {perk}
                              <S.Exponent>2</S.Exponent>
                            </S.Meta>
                          </S.Item>
                        );
                      }
                      return (
                        <S.Item key={perk}>
                          <S.Icon />
                          <S.Meta>{perk}</S.Meta>
                        </S.Item>
                      );
                    })}
                  </S.List>
                </S.Description>
              </S.PerksContainer>
            </S.Card>
          </S.Cards>
        </PaymentDescription>

        <PaymentDetails
          register={register}
          onMarketingOptIn={onMarketingOptInChange}
          setHasAgreedToLegalTerms={hasAgreedToLegalTermsChange}
          errors={errors}
          blockNewYork={isNyBlocked}
          program={program}
        />
        <S.Actions>
          <S.SubmitButton
            data-test={
              DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.PLACE_ORDER_BUTTON
            }
            appearance="primary"
            isDisabled={isSubmitDisabled}
          >
            {content.SUBMIT_BUTTON}
          </S.SubmitButton>
        </S.Actions>
        <S.Legend data-test={DATA_TEST_WEIGHT_MANAGEMENT.PAYMENT_INFO.LEGEND}>
          {content.LEGEND_POINTS.map((point, i) => {
            if (
              point.includes('Lab testing is required for GLP-1 prescriptons')
            ) {
              return (
                <S.LegendPoint key={`legend-${i}`}>
                  <S.Exponent>2</S.Exponent>
                  {point}
                </S.LegendPoint>
              );
            }
            return <S.LegendPoint key={`legend-${i}`}>{point}</S.LegendPoint>;
          })}
        </S.Legend>
      </form>
    </S.Container>
  );
};

export default PaymentInfo;
