import { MockedProvider, MockedProviderProps } from '@apollo/client/testing';
import { render, RenderOptions } from '@testing-library/react';
import { merge } from 'lodash';
import React from 'react';
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import {
  MemoryRouter,
  MemoryRouterProps,
  useHref,
  useLocation,
} from 'react-router-dom';
import configureStore from 'store';

const defaultQueryClient: QueryClientConfig = {
  defaultOptions: {
    queries: {
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: Number.POSITIVE_INFINITY,
      retry: false,
    },
  },
};

type WithProvidersPropsT = {
  apolloMockOverrides?: Partial<MockedProviderProps>;
  queryClientConfig?: QueryClientConfig;
  store?: ReturnType<typeof configureStore>;
  routerConfig?: MemoryRouterProps & { showCurrentUrl?: boolean };
};

interface LocationSpanProps {
  showCurrentUrl?: boolean;
}

const LocationSpanIfNeeded = ({ showCurrentUrl }: LocationSpanProps) => {
  /**
   * Same interface as "history" library
   * https://reactrouter.com/en/6.4.0/utils/location
   */
  const location = useLocation();
  const href = useHref(location);
  if (showCurrentUrl) {
    return <span>current url: {href}</span>;
  }
  return null;
};
const withProviders =
  ({
    apolloMockOverrides = {},
    queryClientConfig = {},
    routerConfig = {},
    store = configureStore({}),
  }: WithProvidersPropsT) =>
  ({ children }: { children: JSX.Element }) =>
    (
      <MockedProvider {...apolloMockOverrides}>
        <QueryClientProvider
          client={new QueryClient(merge(defaultQueryClient, queryClientConfig))}
        >
          <MemoryRouter {...routerConfig}>
            <ReduxProvider store={store}>
              {children}
              <LocationSpanIfNeeded
                showCurrentUrl={routerConfig?.showCurrentUrl}
              />
            </ReduxProvider>
          </MemoryRouter>
        </QueryClientProvider>
      </MockedProvider>
    );

/**
 * Custom render method that wraps unit under test with some app level wrappers
 * and providers
 */
function renderWithProviders(
  ui: React.ReactElement,
  options?: RenderOptions & WithProvidersPropsT,
) {
  const {
    apolloMockOverrides,
    store,
    queryClientConfig,
    routerConfig,
    ...restOptions
  } = options ?? {};

  const result = render(
    withProviders({
      apolloMockOverrides,
      store,
      queryClientConfig,
      routerConfig,
    })({ children: ui }),
    {
      ...restOptions,
    },
  );

  /**
   * Allows for tests to re-render the component with the same providers
   */
  const rerender = (
    children: JSX.Element,
    newOptions: WithProvidersPropsT = {},
  ) =>
    result.rerender(
      withProviders({
        ...options,
        ...newOptions,
      })({ children }),
    );

  return { ...result, rerender };
}

// re-export everything
export * from '@testing-library/react';

export { renderWithProviders, withProviders };
