import { MODAL as dataTest } from 'common/utils/constants/dataTest';
import React from 'react';
import ReactDOM from 'react-dom';

import * as S from './styles';
import useClickOff from './useClickOff';
import useDisableBodyScroll from './useDisableBodyScroll';
import useHasScrollableContent from './useHasScrollableContent';

type ModalPortalProps = {
  children: JSX.Element | JSX.Element[];
};

function ModalPortal(props: ModalPortalProps) {
  const { children } = props;
  const modalEl = document.getElementById('modal');

  return modalEl ? (
    ReactDOM.createPortal(children, modalEl)
  ) : (
    <div>{children}</div>
  );
}

type Props = {
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  footer?: ({
    hasScrollableContent,
  }: {
    hasScrollableContent: boolean;
  }) => boolean | JSX.Element;
};

function Modal(props: Props) {
  const { children, onClose, footer } = props;
  const contentEl = useClickOff(onClose);
  const innerContentWrapperEl = useDisableBodyScroll();
  const hasScrollableContent = useHasScrollableContent(innerContentWrapperEl);

  return (
    <ModalPortal>
      <S.Wrapper>
        <S.Content data-test={dataTest.CONTENT} ref={contentEl}>
          <S.CloseButton data-test={dataTest.CLOSE_BUTTON} onClick={onClose} />
          <S.InnerContent ref={innerContentWrapperEl}>
            {children}
          </S.InnerContent>
          {footer && footer({ hasScrollableContent })}
        </S.Content>
      </S.Wrapper>
    </ModalPortal>
  );
}

export default Modal;
