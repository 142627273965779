/**
 *
 * App.tsx
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import GlobalStyle from 'common/styles/global-styles';
import analytics, { setPageViewDataLayer } from 'common/utils/analytics';
import { LEGACY_APP_ROOT, SHARE_URL } from 'common/utils/constants';
import {
  clearUserLocalStorageAndSession,
  getParameterByName,
} from 'common/utils/helpers';
import {
  isEnterpriseTelehealth,
  setEnterpriseTelehealthValues,
} from 'common/utils/helpers';
import { buildInvalidateUrl } from 'common/utils/request';
import { User } from 'common/utils/types';
import { initJustUno } from 'common/utils/vendor/justuno';
import CreditRedemptionContainer, {
  AddressConfirmationRoute,
  ChooseTestKitOrVisitRoute,
  ProductSelectionRoute,
  RedemptionConfirmationRoute,
} from 'components/CreditRedemption/CreditRedemptionRoutes';
import Intake from 'components/Intake';
import KitCollection from 'components/KitCollection';
import PrivateRoute from 'components/PrivateRoute';
import ScrollToTop from 'components/ScrollToTop';
import CareDashboardContainer from 'containers/CareDashboardContainer';
import ConfirmIdentifiersContainer from 'containers/ConfirmIdentifiersContainer';
import ConfirmIdentifiersPostActionContainer from 'containers/ConfirmIdentifiersPostActionContainer';
import HealthProfileContainer from 'containers/HealthProfileContainer';
import KitResultDetailPageContainer from 'containers/KitResultDetailPageContainer';
import RegistrationContainer from 'containers/RegistrationContainer';
import ReplacementPage from 'containers/ReplacementPageContainer';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  AccountHubRoutes,
  CarePlanRoutes,
  DashboardPageRoutes,
  MedicationRoutes,
  ResultsPageRoutes,
  SettingsPageRoutes,
  TelehealthDashboardRoutes,
  WeightManagementDashboardRoutes,
} from 'pages/AccountHub/AccountRoutes';
import AppointmentRegistrationPage from 'pages/AppointmentRegistrationPage';
import CreditCardForm from 'pages/CreditCardForm';
import Demographics from 'pages/Demographics';
import ErrorPage from 'pages/ErrorPage';
import InsuranceDetailsPage from 'pages/InsuranceDetailsPage';
import InsurancePage from 'pages/InsurancePage';
import InterstitialPage from 'pages/InterstitialPage';
import MedicalIntakePage from 'pages/MedicalIntakePage';
import PaymentPage from 'pages/PaymentTypePage';
import ReplacementConfirmation from 'pages/ReplacementConfirmation';
import SSOErrorPage from 'pages/SSOErrorPage';
import NativeIntake from 'pages/VirtualCare/NativeIntake';
import NativeScheduling from 'pages/VirtualCare/NativeScheduling';
import OnDemandConfirmation from 'pages/VirtualCare/OnDemandConfirmation';
import React, { Fragment, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  authenticateToken,
  receiveAuthentication,
  invalidateAuthRedirectRequest,
} from 'store/actions';
import {
  makeSelectError,
  makeSelectErrorStatus,
  makeSelectUser,
  makeSelectUserId,
} from 'store/selectors';

import 'common/styles/fonts.css';
import '@everlywell/leaves/lib/style.css';
import {
  AppointmentCancelRoutes,
  AppointmentConfirmCancelRoutes,
} from './components/telehealth/AppointmentRoutes';

export type Props = {
  authenticateToken: Function;
  getKitResults: Function;
  receiveAuthentication: Function;
  invalidateAuthRedirectRequest: Function;
};

function resetScrollPosition() {
  window.scrollTo(0, 0);
}

const initializeDatadog = (
  appId?: string,
  clientToken?: string,
  env?: string,
) => {
  const ddConfig = {
    clientToken: clientToken || '',
    site: 'datadoghq.com',
    service: 'Results',
    env: env || '',
    sampleRate: 100,
    silentMultipleInit: true,
  };

  datadogRum.init({
    ...ddConfig,
    applicationId: appId || '',
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
  });

  datadogLogs.init({
    ...ddConfig,
    forwardErrorsToLogs: true,
    telemetrySampleRate: 0,
  });
};

export const LogOut = () => {
  clearUserLocalStorageAndSession();
  window.location.assign(`${LEGACY_APP_ROOT}/logout`);
  return null;
};

const TELEHEALTH_SIGNUP_PATHS = [
  '/virtual-care/intake',
  '/telehealth/sessions',
];

const isNavigatingToTelehealthSignUp = () => {
  const isTelehealthSignUpURI = TELEHEALTH_SIGNUP_PATHS.includes(
    window.location.pathname,
  );
  const hasProgramParam = window.location.search.includes('program');

  return isTelehealthSignUpURI && hasProgramParam;
};

export const App = (props: Props) => {
  const { receiveAuthentication, invalidateAuthRedirectRequest } = props;
  const { enableTelehealthSignUpRedirection } = useFlags();

  const user: User | undefined = useSelector(makeSelectUser());

  useEffect(() => {
    if (user) {
      const userEnterpriseAttributes = user.enterprise_attributes;
      if (
        userEnterpriseAttributes &&
        isEnterpriseTelehealth(userEnterpriseAttributes)
      ) {
        const { partner_slug, enterprise_telehealth_program_slug } =
          userEnterpriseAttributes;
        if (partner_slug && enterprise_telehealth_program_slug) {
          setEnterpriseTelehealthValues(
            partner_slug,
            enterprise_telehealth_program_slug,
          );
        }
      }
      user && setPageViewDataLayer(user);
      // store user enrolled programs in local storage
      const enrolledProgramSlug = user.enrolled_programs.map(
        (program) => program.slug,
      );
      window?.localStorage?.setItem(
        'enrolledPrograms',
        JSON.stringify(enrolledProgramSlug),
      );
    }
  }, [user]);

  useEffectOnce(() => {
    // initialize datadog
    initializeDatadog(
      process.env.DATADOG_APPLICATION_ID,
      process.env.DATADOG_CLIENT_TOKEN,
      process.env.REACT_APP_ENV,
    );

    const urlToken = getParameterByName('token', window.location.search);
    const hasJwtInLocalStorage = isJwtInLocalStorage();
    const isSharedResult = window.location.href.includes(SHARE_URL);
    if (urlToken) {
      authenticateWithToken(urlToken);
    } else if (hasJwtInLocalStorage) {
      const userId = window.localStorage.getItem('userId');

      if (userId) {
        receiveAuthentication();
      } else if (!isSharedResult) {
        // redirect to logout
        invalidateAuthRedirectRequest(buildInvalidateUrl());
      }
    } else if (!isSharedResult) {
      // redirect to sign up if the user wanted to sign up for a telehealth program and the feature is enabled or redirect to login
      const shouldRedirectToSignUp =
        enableTelehealthSignUpRedirection && isNavigatingToTelehealthSignUp();

      const invalidateURL = shouldRedirectToSignUp
        ? buildInvalidateUrl('/sign-up')
        : buildInvalidateUrl();
      invalidateAuthRedirectRequest(invalidateURL);
    }

    document.addEventListener('click', analytics.eventHandler, true);
    window.addEventListener('beforeunload', resetScrollPosition);
    return () => {
      document.removeEventListener('click', analytics.eventHandler, true);
      window.removeEventListener('beforeunload', resetScrollPosition);
    };
  });

  const authenticateWithToken = (urlToken: string) => {
    window.localStorage.removeItem('jwtToken');
    window.localStorage.removeItem('userId');
    props.authenticateToken(urlToken);
  };

  const isJwtInLocalStorage = () =>
    window.localStorage.getItem('jwtToken') &&
    window.localStorage.getItem('jwtToken') !== null;

  useEffectOnce(() => {
    initJustUno();
  });

  useEffect(() => {
    document.title = 'EverlyWell Results Dashboard';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'EverlyWell Results Dashboard';
    document.head.appendChild(metaDescription);

    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,o,d){w[o]=w[o]||function(){w[o][d].push(arguments)};w[o][d]=w[o][d]||[]})(window,'Osano','data');
      window.Osano('onInitialized', consent => hideRejectAllButton());
      function hideRejectAllButton() {
        const noRejectAllStates = ['us-ca', 'us-co', 'us-ct', 'us-ut', 'us-va'];
        if (!noRejectAllStates.includes(window.Osano.cm.jurisdiction)) {
          document.querySelector('.osano-cm-deny').style.display = 'none'
        }
      }
    `;
    document.head.appendChild(script);
  }, []);

  return (
    <ScrollToTop>
      <Fragment>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route element={<AccountHubRoutes />}>
            <Route
              path="/dashboard"
              element={<PrivateRoute component={DashboardPageRoutes} />}
            />
            <Route
              path="/account/settings"
              element={<PrivateRoute component={SettingsPageRoutes} />}
            />
            <Route
              path="/results"
              element={<PrivateRoute component={ResultsPageRoutes} />}
            />
            <Route
              path="/virtual-care"
              element={<PrivateRoute component={TelehealthDashboardRoutes} />}
            />
            <Route
              path="/virtual-care/care-plans"
              element={<PrivateRoute component={CarePlanRoutes} />}
            />
            <Route
              path="/virtual-care/medications"
              element={<PrivateRoute component={MedicationRoutes} />}
            />
            <Route
              path="/weight-management"
              element={
                <PrivateRoute component={WeightManagementDashboardRoutes} />
              }
            />
          </Route>
          <Route
            path="/telehealth/sessions"
            element={<PrivateRoute component={InterstitialPage} />}
          />
          <Route
            path="/virtual-care/scheduling/*"
            element={<PrivateRoute component={NativeScheduling} />}
          />
          <Route
            path="/virtual-care/appointments/:appointmentId/cancel"
            element={<PrivateRoute component={AppointmentCancelRoutes} />}
          />
          <Route
            path="/virtual-care/appointments/:appointmentId/cancel-confirmation"
            element={
              <PrivateRoute component={AppointmentConfirmCancelRoutes} />
            }
          />
          <Route
            path="/virtual-care/demographics"
            element={<PrivateRoute component={Demographics} />}
          />
          <Route
            path="/virtual-care/medical-intake"
            element={<PrivateRoute component={MedicalIntakePage} />}
          />
          <Route
            path="/virtual-care/insurance/add"
            element={<PrivateRoute component={InsurancePage} />}
          />
          <Route
            path="/virtual-care/insurance"
            element={<PrivateRoute component={InsuranceDetailsPage} />}
          />
          <Route path="/virtual-care/payment" element={<CreditCardForm />} />
          <Route
            path="/virtual-care/on-demand/confirmation"
            element={<OnDemandConfirmation />}
          />
          <Route
            path="/virtual-care/payment-type"
            element={<PrivateRoute component={PaymentPage} />}
          />
          <Route
            path="/virtual-care/registration"
            element={<PrivateRoute component={AppointmentRegistrationPage} />}
          />
          <Route
            path="/virtual-intake/*"
            element={<PrivateRoute component={NativeIntake} />}
          />
          <Route
            path="/results/:kitResultIdOrPublishHash"
            element={<PrivateRoute component={KitResultDetailPageContainer} />}
          />
          <Route
            path="/share/:kitResultIdOrPublishHash"
            element={<KitResultDetailPageContainer viewingSharedKitResult />}
          />
          <Route
            path="/replacement/confirmation"
            element={<ReplacementConfirmation />}
          />
          <Route
            path="/replacement"
            element={<PrivateRoute component={ReplacementPage} />}
          />
          <Route path="/logout" element={<LogOut />} />
          <Route
            path="/register/"
            element={<PrivateRoute component={RegistrationContainer} />}
          />
          <Route
            path="/identifiers-confirmed/:action"
            element={
              <PrivateRoute component={ConfirmIdentifiersPostActionContainer} />
            }
          />
          <Route
            path="/confirm-identifiers/:kitIssueId"
            element={<PrivateRoute component={ConfirmIdentifiersContainer} />}
          />
          <Route
            path="/health-profile/*"
            element={<PrivateRoute component={HealthProfileContainer} />}
          />

          <Route
            path="/collection"
            element={<PrivateRoute component={KitCollection} />}
          />
          <Route
            path="/virtual-care/intake"
            element={<PrivateRoute component={Intake} />}
          />
          <Route
            path="/health-path"
            element={<PrivateRoute component={CareDashboardContainer} />}
          />
          <Route
            path="/credit-redemption"
            element={<PrivateRoute component={CreditRedemptionContainer} />}
          >
            <Route
              index
              element={
                <Navigate to={'/credit-redemption/select-product'} replace />
              }
            />
            <Route
              path="/credit-redemption/select-product"
              element={<PrivateRoute component={ChooseTestKitOrVisitRoute} />}
            />
            <Route
              path="/credit-redemption/address"
              element={<PrivateRoute component={AddressConfirmationRoute} />}
            />
            <Route
              path="/credit-redemption/products"
              element={<PrivateRoute component={ProductSelectionRoute} />}
            />
            <Route
              path="/credit-redemption/confirmation"
              element={<PrivateRoute component={RedemptionConfirmationRoute} />}
            />
          </Route>
          <Route path="/sso-error" element={<SSOErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <GlobalStyle />
      </Fragment>
    </ScrollToTop>
  );
};

// @ts-ignore
export const mapStateToProps = createStructuredSelector({
  error: makeSelectError(),
  errorStatus: makeSelectErrorStatus(),
  userId: makeSelectUserId(),
});

export function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authenticateToken: (token: string) => dispatch(authenticateToken(token)),
    receiveAuthentication: () => dispatch(receiveAuthentication()),
    invalidateAuthRedirectRequest: (url: string) =>
      dispatch(invalidateAuthRedirectRequest(url)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const AppWithLD = withLDConsumer()(App);

export default compose<any>(withConnect)(AppWithLD);
