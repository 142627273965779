import React, {
  useLayoutEffect,
  useState,
  useEffect,
  RefObject,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';

import * as S from './styles';
import TooltipWrapper from './TooltipWrapper';

const MIN_LEFT_POSITION = 36;

type TooltipPortalProps = {
  children: JSX.Element | JSX.Element[];
};

function TooltipPortal(props: TooltipPortalProps) {
  const { children } = props;
  const tooltipEl = document.getElementById('tooltip');

  return tooltipEl ? (
    ReactDOM.createPortal(children, tooltipEl)
  ) : (
    <div>{children}</div>
  );
}

type Props = {
  children: JSX.Element | JSX.Element[];
  triggerEl: RefObject<HTMLDivElement>;
  iconEl: RefObject<HTMLDivElement>;
  tooltipWidth?: number;
  isShown: boolean;
  [prop: string]: any;
};

function Tooltip(props: Props) {
  const { triggerEl, iconEl, children, isShown, ...rest } = props;
  const tooltipEl = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [triangleLeftPosition, setTriangleLeftPosition] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  // Solves the issue of tapping away in iOS to close the tooltip
  useEffect(() => {
    if (isShown) {
      document.body.style.cursor = 'pointer';
    } else {
      document.body.style.cursor = 'default';
    }
  });

  // Re-calculate on resize to solve the issue of the orientation changes.
  useEffect(() => {
    function handleForceUpdate() {
      setShouldUpdate(prevShouldUpdate => !prevShouldUpdate);
    }

    handleForceUpdate();

    window.addEventListener('resize', handleForceUpdate);

    return () => {
      window.removeEventListener('resize', handleForceUpdate);
    };
  }, []);

  useLayoutEffect(() => {
    if (!tooltipEl.current || !iconEl.current || !triggerEl.current) {
      return;
    }

    function getOffsetTop(el: HTMLDivElement, offset = 0): number {
      if (el.offsetParent && el.offsetParent.tagName !== 'BODY') {
        return getOffsetTop(
          el.offsetParent as HTMLDivElement,
          offset + el.offsetTop,
        );
      }
      return offset + el.offsetTop;
    }

    let tooltipLeft =
      triggerEl.current.offsetLeft +
      triggerEl.current.offsetWidth / 2 -
      tooltipEl.current.offsetWidth / 2;

    // maximum left position before tooltip goes outside of page boundary
    const maxLeft =
      document.body.offsetWidth -
      tooltipEl.current.offsetWidth -
      MIN_LEFT_POSITION;

    if (tooltipLeft < MIN_LEFT_POSITION) {
      tooltipLeft = MIN_LEFT_POSITION;
    } else if (tooltipLeft > maxLeft) {
      tooltipLeft = maxLeft;
    }

    setTriangleLeftPosition(
      iconEl.current.offsetLeft +
        iconEl.current.offsetWidth / 2 -
        tooltipLeft -
        S.TRIANGLE_WIDTH / 2,
    );

    setPosition({
      top:
        getOffsetTop(triggerEl.current) -
        (tooltipEl.current.offsetHeight + S.TRIANGLE_HEIGHT),
      left: tooltipLeft,
    });
  }, [tooltipEl, triggerEl, iconEl, shouldUpdate, isShown]);

  return (
    <TooltipPortal>
      <S.Wrapper
        ref={tooltipEl}
        role="tooltip"
        style={{ ...position, visibility: isShown ? 'visible' : 'hidden' }}
        triangleLeftPosition={triangleLeftPosition}
        {...rest}
      >
        {children}
      </S.Wrapper>
    </TooltipPortal>
  );
}

export { TooltipWrapper };
export default Tooltip;
