import { breakpoints } from '@everlywell/leaves';
import useUser from 'common/hooks/useUser';
import { isEnterpriseCustomer } from 'common/utils/helpers';
import { isUserWeightCarePlusActiveMember } from 'common/utils/user';
import Layout from 'components/Layout';
import DashboardPageContainer from 'containers/DashboardPageContainer';
import SettingsPageContainer from 'containers/SettingsPageContainer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DashboardPage from 'pages/AccountHub/pages/DashboardPage';
import SettingsPage from 'pages/AccountHub/pages/SettingsPage';
import React from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import CarePlanPage from '../CarePlanPage';
import * as S from './AccountHub.styles';
import Navbar from './components/Navbar/Navbar';
import MedicationPage from './pages/MedicationPage';
import ResultsPage from './pages/ResultsPage';
import TelehealthDashboard from './pages/TelehealthDashboard';
import WeightManagementDashboard from './pages/WeightManagementDashboard';

export type AccountHubProps = {};

export function AccountHubRoutes(props: AccountHubProps) {
  const isEnterpriseHeader = isEnterpriseCustomer();
  const [searchParams] = useSearchParams();
  // This is true for the mobile app
  const isEmbedded =
    searchParams.get('embedded') === 'true' ||
    sessionStorage.getItem('embedded') === 'true';

  const { width } = useWindowSize();
  const isDesktop = width >= breakpoints.forDesktopUp;
  if (isEnterpriseHeader) {
    return <Outlet />;
  }

  return (
    <Layout>
      {/* Show the navbar tabs: If not desktop and not the mobile app (mobile web) */}
      {!isDesktop && !isEmbedded ? <Navbar /> : null}
      <S.Container>
        {/* Show the new side nav: If it's desktop and the feature flag is ON */}
        {isDesktop && (
          <S.NavWrapper>
            <Navbar />
          </S.NavWrapper>
        )}
        <S.InsideContainer>
          <Outlet />
        </S.InsideContainer>
      </S.Container>
    </Layout>
  );
}

const useWCDashboard = () => {
  const { user, isLoading: isLoadingUser } = useUser();
  const isActiveWeightMember = user && isUserWeightCarePlusActiveMember(user);
  const { isWeightManagementEnabled } = useFlags();

  if (isLoadingUser) {
    return <Outlet />;
  }

  return isWeightManagementEnabled &&
    !isEnterpriseCustomer() &&
    isActiveWeightMember ? (
    <WeightManagementDashboard />
  ) : (
    <Navigate to="/dashboard" />
  );
};

const DashboardPageRoutes = () =>
  isEnterpriseCustomer() ? <DashboardPageContainer /> : <DashboardPage />;
const SettingsPageRoutes = () =>
  isEnterpriseCustomer() ? <SettingsPageContainer /> : <SettingsPage />;

const ResultsPageRoutes = () =>
  isEnterpriseCustomer() ? <Navigate to="/dashboard" /> : <ResultsPage />;
const TelehealthDashboardRoutes = () =>
  isEnterpriseCustomer() ? null : <TelehealthDashboard />;
const MedicationRoutes = () =>
  isEnterpriseCustomer() ? null : <MedicationPage />;
const CarePlanRoutes = () => (isEnterpriseCustomer() ? null : <CarePlanPage />);
const WeightManagementDashboardRoutes = () => useWCDashboard();

export {
  DashboardPageRoutes,
  CarePlanRoutes,
  MedicationRoutes,
  ResultsPageRoutes,
  SettingsPageRoutes,
  TelehealthDashboardRoutes,
  WeightManagementDashboardRoutes,
};
