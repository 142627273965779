import { TelehealthMedication } from 'common/apis/telehealthApis';

import { buildTelehealthPharmacy } from './telehealthPharmacy';

const baseTelehealthMedication: TelehealthMedication = {
  product_name: 'levothyroxine 75 mcg (0.075 mg) oral tablet',
  quantity: '30',
  refills: '12',
  status: 'Error',
  directions: 'Take once a day on empty stomach',
  date_written: '2022-08-25T19:14:02.807',
  pharmacy: buildTelehealthPharmacy(),
};

export const buildTelehealthMedication = (
  overrideProps: Partial<TelehealthMedication> = {},
) => ({
  ...baseTelehealthMedication,
  ...overrideProps,
});
