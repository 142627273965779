import React from 'react';

import * as S from './BaseCard.styles';

export type BaseCardProps = {
  image?: React.ReactNode;
  content?:
    | React.ReactNode
    | {
        title: string | React.ReactNode;
        description: string | React.ReactNode;
      };
  cta?: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
};

/**
 * This card standardizes the look and feel of cards used in the Account Hub
 */
function BaseCard(props: BaseCardProps) {
  const { image, content, cta, ref } = props;

  const bodyContent: any =
    content &&
    typeof content === 'object' &&
    'title' in content &&
    content.title &&
    content.description ? (
      <>
        <S.Title>{content.title}</S.Title>

        <S.Text>{content.description}</S.Text>
      </>
    ) : (
      content
    );

  return (
    <S.ContainerWrapper ref={ref}>
      <S.TopContainer>
        {image && <S.IconContainer>{image}</S.IconContainer>}
        <S.InternalItem>
          <S.TopContainer>
            {bodyContent && <S.CopyContainer>{bodyContent}</S.CopyContainer>}
            {cta && (
              <S.ButtonContainer>
                <S.ButtonContainerInner>{cta}</S.ButtonContainerInner>
              </S.ButtonContainer>
            )}
          </S.TopContainer>
        </S.InternalItem>
      </S.TopContainer>
    </S.ContainerWrapper>
  );
}

export default BaseCard;
