import 'react-intersection-observer/test-utils';
import {
  kitResult as fsKitResult,
  fsTest,
  fsKitResultWithoutSections,
} from 'common/fixtures/food_sensitivity';
import { kitResult as numericalKitResult } from 'common/fixtures/numerical';
import { kitResult as descriptiveKitResult } from 'common/fixtures/std_female';
import { kitResult as thyroidKitResult } from 'common/fixtures/thyroid';
import useApi from 'common/hooks/useApi';
import { buildKitResult } from 'common/testUtils/builders/kitResult';
import {
  KIT_RESULT_DETAIL,
  WHATS_NEXT_SECTION,
  RESULT_DETAILS as DATA_TEST,
} from 'common/utils/constants/dataTest';
import { screen } from 'common/utils/reactTestingLibraryHelpers';
import { findByTestAttr, renderPageContainer } from 'common/utils/testHelpers';
import FoodSensitivityResultDetail from 'components/FoodSensitivityResultDetail';
import { shallow } from 'enzyme';
import SavePdfButton from 'pages/KitResultDetailPage/SavePdfButton';
import React from 'react';

import NewLayoutKitResultDetailPage from '..';

import { QueryClient, QueryClientProvider } from 'react-query';

import ConsiderChangesSection from '../../ConsiderChangesSection';
import GetHelpSection from '../../GetHelpSection';
import MarkerReplacementSection from '../../MarkerReplacementSection';
import ResourcesLinksSection from '../../ResourcesLinksSection';
import WhatsNextSection from '../../WhatsNextSection';

const testProps = {
  contentToken: '1234',
  kitResultIdOrPublishHash: '1234',
  testId: 3,
  viewingSharedKitResult: false,
  session: {
    userId: '1',
    token: 'test',
  },
  loadingContent: false,
  userId: '1',
  getKitResult: jest.fn(),
  getKitResultContent: jest.fn(),
  setUserData: jest.fn(),
};

const renderComponent = (props: any) =>
  shallow(<NewLayoutKitResultDetailPage {...testProps} {...props} />, {
    wrappingComponent: QueryClientProvider,
    wrappingComponentProps: { client: new QueryClient() },
  });

const getPersonalizationsMock = jest.fn();
jest.mock('common/hooks/useApi');
jest
  .spyOn(window.navigator, 'userAgent', 'get')
  .mockReturnValue('com.everlyhealth.ios');

describe('<NewLayoutKitResultDetailPage />', () => {
  beforeEach(() => {
    (useApi as jest.Mock).mockReturnValue({
      getPersonalizations: getPersonalizationsMock,
    });
  });

  describe('when viewing a Food Sensitivity test', () => {
    it('should render the FoodSensitivityResultDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });

      expect(
        renderedComponent.find('FoodSensitivityResultDetail'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a numerical test', () => {
    it('should render the NumerialResultsDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 47,
        },
        testId: 47,
      });

      expect(
        renderedComponent.find('Connect(NumericalResultDetail)'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a descriptive test', () => {
    it('should render the DescriptiveResultDetail component', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...descriptiveKitResult,
          test_id: 55,
        },
        testId: 55,
      });

      expect(
        renderedComponent.find('Connect(DescriptiveResultDetail)'),
      ).toHaveLength(1);
    });
  });

  describe('when viewing a shared result', () => {
    it('should render the shared result information banner', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(
        findByTestAttr(renderedComponent, KIT_RESULT_DETAIL.SHARED_RESULT_INFO),
      ).toHaveLength(1);
    });

    it('should not render the product upsell component', () => {
      const renderedComponent = renderComponent({
        viewingSharedKitResult: true,
        kitResult: {
          ...fsKitResult,
        },
      });

      expect(
        findByTestAttr(
          renderedComponent,
          WHATS_NEXT_SECTION.PRODUCT_UPSELL_BUTTON,
        ),
      ).toHaveLength(0);
    });

    it('should not render the cobranded ancestry banner for ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 86,
        },
        viewingSharedKitResult: true,
        testId: 86,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(0);
    });

    it('should not render the sub header', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(findByTestAttr(renderedComponent, 'sub-header')).toHaveLength(0);
    });

    it('should not render TakeAction', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: true,
      });

      expect(renderedComponent.find('Connect(TakeActionSection)')).toHaveLength(
        0,
      );
    });

    it('should not load personalizations', async () => {
      const options = {
        propsOverride: {
          kitResult: fsKitResult,
          viewingSharedKitResult: true,
        },
      };
      renderPageContainer(NewLayoutKitResultDetailPage, options);
      expect(getPersonalizationsMock).not.toHaveBeenCalled();
    });
  });

  describe('when viewing a non-shared result', () => {
    it('should not render the shared result information banner', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
      });

      expect(
        findByTestAttr(renderedComponent, KIT_RESULT_DETAIL.SHARED_RESULT_INFO),
      ).toHaveLength(0);
    });

    it('should render the cobranded ancestry banner for ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...numericalKitResult,
          test_id: 86,
        },
        testId: 86,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(1);
    });

    it('should not render the cobranded ancestry banner for non-ancestry tests', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          test_id: 83,
        },
        testId: 83,
      });

      expect(
        findByTestAttr(renderedComponent, 'cobranded-ancestry-banner'),
      ).toHaveLength(0);
    });

    it('should render TakeAction to show Live Webinar', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showConsult')).toEqual(true);
      expect(renderedComponent.prop('showLiveWebinar')).toEqual(true);
    });

    it('should not render TakeAction when showConsult is false', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
        viewingSharedKitResult: false,
        showConsult: false,
      });
      expect(renderedComponent.find('Connect(TakeActionSection)')).toHaveLength(
        0,
      );
    });

    it('should render TakeAction show Live Webinar when Consult is not available', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          consult: {
            available: false,
            available_until: '2050-07-31T14:34:59Z',
          },
        },
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showConsult')).toEqual(false);
      expect(renderedComponent.prop('showLiveWebinar')).toEqual(true);
    });

    it('should render TakeAction to show recorded Webinar when test includes a recorded_webinar', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          test: {
            ...fsTest,
            recorded_webinar: true,
          },
        },
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showLiveWebinar')).toEqual(true);
      expect(renderedComponent.prop('isWebinarEnabledForTest')).toEqual(true);
    });

    it('should render TakeAction and show recorded Webinar even if Consult & Live Webinar are not available', () => {
      const renderedComponent = renderComponent({
        kitResult: {
          ...fsKitResult,
          live_webinar_available: false,
          consult: {
            available: false,
            available_until: '2050-07-31T14:34:59Z',
          },
          test: {
            ...fsTest,
            recorded_webinar: true,
          },
        },
        viewingSharedKitResult: false,
        showConsult: true,
      }).find('Connect(TakeActionSection)');

      expect(renderedComponent.prop('showLiveWebinar')).toEqual(false);
      expect(renderedComponent.prop('isWebinarEnabledForTest')).toEqual(true);
    });

    it('should load personalizations', async () => {
      const options = {
        propsOverride: {
          kitResult: fsKitResult,
          viewingSharedKitResult: false,
        },
      };
      renderPageContainer(NewLayoutKitResultDetailPage, options);
      expect(getPersonalizationsMock).toHaveBeenCalled();
    });
  });

  it('should not render when there are no kitResult', () => {
    const renderedComponent = renderComponent({
      kitResult: undefined,
    });

    expect(renderedComponent.isEmptyRender()).toBe(true);
  });

  describe('when telehealth consult is available', () => {
    it('displays the telehealth consult link', () => {
      const kit = {
        status: 'results_approved',
        test: 41,
        lab: 2345,
        markers: [16],
        consult: {
          available_until: new Date('2050-01-01').toISOString(),
        },
        marker_results: [3611189],
      };

      const options = {
        propsOverride: {
          ...testProps,
          showConsult: true,
          kitResult: { ...thyroidKitResult },
          testId: 41,
        },
        stateOverride: {
          app: { loadingContent: false },
          entities: {
            kits: {
              K123: kit,
            },
            tests: {
              41: thyroidKitResult.test,
            },
            content_snippets: {
              '1234': {},
              '944cfa6f-c7a5-4d89-a576-392a3741e7d8': {},
            },
          },
        },
      };

      renderPageContainer(NewLayoutKitResultDetailPage, options);
      expect(
        screen.queryByRole('button', { name: /Telehealth/ }),
      ).toBeDefined();
    });
  });

  describe('should render the First Section: Result and its components', () => {
    it('the first section should be the results section', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .first()
          .prop('sectionName'),
      ).toEqual('Results');
    });

    it('the results section should have a <FoodSensitivityResultDetail>', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .first()
          .containsMatchingElement(<FoodSensitivityResultDetail />),
      ).toBeTruthy();
    });
  });

  describe('should render the Second Section: Next Steps and its components', () => {
    it('the second section should be the Next Steps Section', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent.find('ResultDetailSection').at(1).prop('sectionName'),
      ).toEqual('Next Steps');
    });

    it('the Next Steps Section should have Whats Next Component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .at(1)
          .containsMatchingElement(
            <WhatsNextSection contentToken={testProps.contentToken} />,
          ),
      ).toBeTruthy();
    });

    it('the Next Steps Section should have Consider Changes Component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .at(1)
          .containsMatchingElement(
            <ConsiderChangesSection contentToken={testProps.contentToken} />,
          ),
      ).toBeTruthy();
    });
  });

  describe('when Next Steps header is not defined', () => {
    it('does not render', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResultWithoutSections,
      });

      expect(
        renderedComponent
          .find('ResultDetailSection')
          .containsMatchingElement(
            <WhatsNextSection contentToken={testProps.contentToken} />,
          ),
      ).toBeFalsy();
    });
  });

  describe('should render the Third Section: Resources and its components', () => {
    it('the third section should be the Resources Section', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent.find('ResultDetailSection').at(2).prop('sectionName'),
      ).toEqual('Resources');
    });

    it('the Resources Section should have ResourcesLinksSection Component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .at(2)
          .containsMatchingElement(
            <ResourcesLinksSection contentToken={testProps.contentToken} />,
          ),
      ).toBeTruthy();
    });

    it('the Resources Section should have CommonQuestionsSection Component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .at(2)
          .find('CommonQuestionsSection'),
      ).toHaveLength(1);
    });
  });

  describe('when Resources header is not defined', () => {
    it('does not render', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResultWithoutSections,
      });

      expect(
        renderedComponent
          .find('ResultDetailSection')
          .containsMatchingElement(
            <ResourcesLinksSection contentToken={testProps.contentToken} />,
          ),
      ).toBeFalsy();
    });
  });

  describe('should render the Fourth Section: GetHelp and its components', () => {
    it('the fourth section should be the GetHelp Section', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent.find('ResultDetailSection').at(3).prop('sectionName'),
      ).toEqual('Help');
    });

    it('the GetHelp Section should have GetHelpSection Component', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResult,
      });
      expect(
        renderedComponent
          .find('ResultDetailSection')
          .at(3)
          .containsMatchingElement(
            <GetHelpSection contentToken={testProps.contentToken} />,
          ),
      ).toBeTruthy();
    });
  });

  describe('when GetHelp header is not defined', () => {
    it('does not render', () => {
      const renderedComponent = renderComponent({
        kitResult: fsKitResultWithoutSections,
      });

      expect(
        renderedComponent
          .find('ResultDetailSection')
          .containsMatchingElement(
            <GetHelpSection contentToken={testProps.contentToken} />,
          ),
      ).toBeFalsy();
    });
  });

  describe('should render the SavePdfButton', () => {
    const kitResult = {
      ...fsKitResult,
      id: 112233,
      needs_replacement: false,
      number: 'KRDB99CD65BB5643438B506AF7830D60',
      tracking_url: 'www.ups.com',
      product_slug: 'food-sensitivity',
      clia: '39D0673919',
      serial_number: 65549690,
    };
    it('renders the SavePdfButton', () => {
      const mockKitResult = {
        ...kitResult,
        test: { ...fsTest, name: 'testing' },
      };

      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <SavePdfButton
            kitResult={mockKitResult}
            key={DATA_TEST.SAVE_PDF_BUTTON}
          />,
        ),
      ).toBeTruthy();
    });

    it('Does not renders the SavePdfButton when viewingSharedKitResult is true', () => {
      const mockKitResult = {
        ...kitResult,
        test: { ...fsTest, name: 'testing' },
      };

      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
        viewingSharedKitResult: true,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <SavePdfButton
            kitResult={mockKitResult}
            key={DATA_TEST.SAVE_PDF_BUTTON}
          />,
        ),
      ).toBeFalsy();
    });
  });

  describe('should render MarkerReplacementSection', () => {
    it('renders the MarkerReplacementSection', () => {
      const markerReplacement = {
        status: 'requested',
        replacement_product: 'test',
        replacement_result_number: 'R382939824',
        requested_at: '',
        resulted_at: '',
      };

      const mockKitResult = buildKitResult({
        serial_number: 65549690,
        marker_replacement: markerReplacement,
      });

      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <MarkerReplacementSection kitResult={mockKitResult} />,
        ),
      ).toBeTruthy();
    });

    it('Does not renders the ReplaceTestButton when marker replacement is not available', () => {
      const mockKitResult = buildKitResult({
        serial_number: 65549690,
      });

      const renderedComponent = renderComponent({
        kitResult: mockKitResult,
      });

      expect(
        renderedComponent.containsMatchingElement(
          <MarkerReplacementSection kitResult={mockKitResult} />,
        ),
      ).toBeFalsy();
    });
  });
});
