/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'sanitize.css/sanitize.css';

import App from 'App';
import { queryClient } from 'common/apis/instances';
import { init } from 'common/utils/analytics';
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'common/utils/vendor/helpscout';

import configureStore from './store';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState);

const container = document.getElementById('app');
const root = ReactDOM.createRoot(container!);

const tagManagerArgs = {
  gtmId: 'GTM-KKDV8L',
};

TagManager.initialize(tagManagerArgs);

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID || '',
    user: {
      anonymous: true,
    },
  });

  root.render(
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <LDProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LDProvider>
        </Provider>
      </QueryClientProvider>
    </GlobalErrorBoundary>,
  );
};

renderApp();

if (module.hot) {
  module.hot.accept(['App.tsx'], () => {
    root.unmount();
    renderApp();
  });
}

// @ts-ignore
if (!window.mixpanel) {
  // @ts-ignore
  init().then((mixpanel) => (window.mixpanel = mixpanel));
}
