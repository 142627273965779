import { getAccountHubKitResults } from 'common/apis/kitResultApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { User } from 'common/utils/types';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import ErrorCard from '../../../../components/ErrorCard';
import { CardHeaderLink } from '../Cards/BaseCard.styles';
import { ReadyTestResultsCardSkeleton } from '../Cards/ReadyTestResultsCard';
import ShopProductsCard from '../Cards/ShopProductsCard';
import * as S from './TestResultsSection.styles';

export type TestResultsSectionProps = {
  user?: User;
};

/**
 * This section displays:
 * - latest completed kit info and a CTA to view all results
 * - Display shop products section, when there is not any completed results
 */

function TestResultsSection({ user }: TestResultsSectionProps) {
  const [isTestResultsViewed, setIsTestResultsViewed] =
    useState<boolean>(false);
  const {
    data: response,
    isLoading,
    error,
    refetch,
  } = useQuery('account-hub-kit-results', () => getAccountHubKitResults());

  const kitResult = response?.data?.[0];

  useEffect(() => {
    if (user && !isTestResultsViewed) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
        data: {
          label: ANALYTICS.LABELS.ACCOUNT_HUB.TEST_RESULTS_CARD,
          category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        },
      });
      setIsTestResultsViewed(true);
    }
  }, [user, isTestResultsViewed]);

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: ANALYTICS.LABELS.ACCOUNT_HUB.TEST_RESULTS,
      },
    });
  };

  return (
    <section>
      <CardHeaderLink
        component={Link}
        css={{ display: 'flex' }}
        to="/results"
        onClick={handleClick}
      >
        Test Results
      </CardHeaderLink>
      {(error as string) && <ErrorCard onRetry={refetch} />}
      {isLoading && <ReadyTestResultsCardSkeleton />}
      {!isLoading && !error && (
        <>
          {!kitResult && <ShopProductsCard />}
          {kitResult && (
            <S.KitResultCard kitResult={kitResult} category="Account Hub" />
          )}
        </>
      )}
    </section>
  );
}

export default TestResultsSection;
