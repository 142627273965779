import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import React, { useEffect } from 'react';

import { useFormModalContext } from '../../providers';
import { FormBuilderField } from '../../utils/types';
import AgreeToAboveField from '../AgreeToAboveField/AgreeToAboveField';
import BMIField from '../BMIField';
import CheckboxFieldGroup from '../CheckboxFieldGroup';
import DateField from '../DateField';
import DOBField from '../DOBField/DOBField';
import DocumentField from '../DocumentField';
import DropdownField from '../DropdownField';
import HeightField from '../HeightField/HeightField';
import LabelField from '../LabelField/LabelField';
import LocationField from '../LocationField/LocationField';
import NameField from '../NameField/NameField';
import PhoneField from '../PhoneField/PhoneField';
import PulseField from '../PulseField';
import RadioFieldGroup from '../RadioFieldGroup';
import ReadOnlyField from '../ReadOnlyField';
import TextAreaField from '../TextAreaField';
import TextField from '../TextField';
import WaistCircumferenceField from '../WaistCircumference/WaistCircumferenceField';
import WeightField from '../WeightField/WeightField';
import useFieldTracking from './hooks/useFieldTracking';
import useFieldVisibility from './hooks/useFieldVisibility';
import { useHealthieExclusionLogic } from './hooks/useHealthieExclusionLogic';

export type FieldProps = FormBuilderField;

/**
 * Render or not the children based on the custom_module_condition.
 */
function Field(props: FieldProps) {
  const { custom_module_condition, mod_type } = props;
  const { programSlug } = useProgramSlug();
  const label = useFieldTracking(props, programSlug);
  const isVisible = useFieldVisibility(custom_module_condition);

  const { setModalsToBeShown } = useFormModalContext();
  const shouldDisplayModal = useHealthieExclusionLogic();

  const Field =
    mod_type === 'document' && !label?.startsWith('[upload]')
      ? null
      : fieldsMap[mod_type];

  useEffect(() => {
    if (shouldDisplayModal) {
      setModalsToBeShown(true);
    }

    return () => {
      setModalsToBeShown(false);
    };
  }, [setModalsToBeShown, shouldDisplayModal]);

  if (!isVisible || !Field) {
    return null;
  }

  return <Field {...props} label={label} />;
}

export default Field;

const fieldsMap: Record<
  TelehealthCustomModule['mod_type'],
  React.FC<FormBuilderField> | null
> = {
  agree_to_above: AgreeToAboveField,
  'BMI(in.)': BMIField,
  checkbox: CheckboxFieldGroup,
  date: DateField,
  dob: DOBField,
  document: DocumentField,
  dropdown: DropdownField,
  ethnicity: RadioFieldGroup,
  gender_identity: RadioFieldGroup,
  'Height (in.)': HeightField,
  horizontal_radio: RadioFieldGroup,
  label: LabelField,
  location: LocationField,
  name: NameField,
  phone: PhoneField,
  pulse: PulseField,
  race: RadioFieldGroup,
  radio: RadioFieldGroup,
  read_only: ReadOnlyField,
  sex_at_birth: RadioFieldGroup,
  text: TextField,
  textarea: TextAreaField,
  Weight: WeightField,
  waist_circumference: WaistCircumferenceField,
};
