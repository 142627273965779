import { Dropdown, DropdownOptionType } from '@everlywell/leaves';
import {
  getTelehealthSession,
  saveTelehealthUserSession,
} from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { stateNameToAbbreviation } from 'common/utils/constants/states';
import {
  HEALTHIE_WHITE_LABEL_SSO_LINK,
  LEGACY_APP_ROOT,
} from 'common/utils/constants/urls';
import { appendQueryParamsToUrl } from 'common/utils/helpers';
import { ConsumerAttributes } from 'common/utils/types';
import { userPlanId } from 'common/utils/userPlanId';
import Grid from 'components/Grid';
import Layout from 'components/Layout';
import DOBField from 'components/telehealth/FormBuilder/components/DOBField';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ProviderImage from './assets/telehealth-interstitial-image.png';
import NoAreaServiceModal from './components/NoAreaServiceModal';
import NoAsyncServiceModal from './components/NoAsyncServiceModal';
import ShareResultsModal from './components/ShareResultsModal';
import InterstitialPageSkeleton from './InterstitialPage.skeleton';
import * as S from './InterstitialPage.styles';
import SLUGS from './utils/slugs';
export const {
  DEMOGRAPHICS_FORM_ROUTE,
  DYNAMIC_FORM_ROUTE,
  FALLBACK_PROGRAM_SLUG,
  STI_ON_DEMAND_PROGRAM_SLUG,
  STI_VIRTUAL_CARE_PROGRAM_SLUG,
  VIRTUAL_CARE_VISIT,
  WM_FULL_PROGRAM_SLUG,
  WM_PROGRAM_SLUG,
} = SLUGS;

export const HEADER_TEXT =
  'What state will you join your Virtual Care Visit from?';
export const CTA_TEXT = 'Continue to scheduling';
export const ASYNC_HEADER_TEXT = 'What state are you in?';
export const ASYNC_CTA_TEXT = 'Continue';

export type InterstitialPageProps = {};

type FormValues = {
  state_abbrev: string;
  time_zone: string;
  program: string;
  dob: string;
};

type InterstitialPageFlags = {
  allowedStatesWithService?: {
    verticals: string[];
    states: string[];
    redirection: {
      [key: string]: string;
    };
  };
  telehealthInterstitialPage: boolean;
  telehealthResultsShare: boolean;
  showDynamicIntakeFlow: boolean;
  authorizedStatesForService?: {
    verticals: {
      [key: string]: {
        program_redirection_slug: string;
        states: string[];
      };
    };
  };
};

/*
 * This function is used to check if the program is allowed in the enable verticals.
 * based on the allowed verticals.
 * @param program - the program slug
 * @param allowedVerticals - the allowed verticals from feature flag.
 */
function isProgramAllowed(
  program: string,
  allowedVerticals: string[] | undefined,
): boolean {
  if (allowedVerticals) {
    return allowedVerticals.some((vertical) => vertical === program);
  }
  return false;
}

/**
 * This component is used to allow users to select
 * the state they will be joining their virtual care visit from.
 */
function InterstitialPage(props: InterstitialPageProps) {
  const {
    allowedStatesWithService,
    telehealthInterstitialPage,
    telehealthResultsShare,
    showDynamicIntakeFlow,
    authorizedStatesForService,
    // TODO: Refactor allowedStatesWithService (On Demand STI) flag
    // to utilize authorizedStatesForService (AsyncRx and more) flag
    // https://everlyhealth.atlassian.net/browse/VCP-97
  } = useFlags<InterstitialPageFlags>();
  const [searchParams, setSearchParams] = useSearchParams();
  // ?program=virtual-care-visit&planId=1
  const program = searchParams.get('program') || FALLBACK_PROGRAM_SLUG;
  const planId = searchParams.get('planId');
  const isAsyncProgram = program.includes('async');
  const { user } = useUser();

  const userDOB = (user?.consumer_attributes as ConsumerAttributes)?.dob ?? '';

  useProgramSlug(program);
  userPlanId.planId = planId;

  const [shareResultsModal, setShareResultsModal] = useState<boolean>(false);
  const [noAreaServiceModal, setNoAreaServiceModal] = useState<boolean>(false);
  const [showNoAsyncServiceModal, setNoAsyncServiceModal] =
    useState<boolean>(false);

  const isResultsInterstitialDisabled = telehealthInterstitialPage === false;
  const isShareResultsEnabled = telehealthResultsShare === true;
  const isDynamicIntakeFlowEnabled = showDynamicIntakeFlow === true;

  const redirectToHealthie =
    (searchParams.get('redirectToProvider') ?? 'false') === 'true';

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const useFormMethods = useForm<FormValues>({
    defaultValues: {
      dob: userDOB,
      state_abbrev: '',
      time_zone: timeZone,
      program,
    },
  });

  const { handleSubmit, register, errors, setValue, watch, getValues } =
    useFormMethods;

  const {
    data: response,
    isLoading,
    isSuccess,
  } = useQuery(
    ['telehealth-session'],
    () => getTelehealthSession({ program }),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        navigate('/sso-error');
      },
    },
  );

  const results_sharing_consent = Boolean(
    response?.data?.results_sharing_consented_at,
  );

  const shouldDisplayDOBField =
    response?.data?.program_integration === 'consumer_everly';

  useEffect(() => {
    /**
     * This approach is used to set the default value for form values
     * with values that come from the GET endpoint. We're unable to use the
     * `onSuccess` callback from `useQuery` because it's called before the
     * form elements are mounted - resulting in the form values not being set.
     */
    if (isSuccess && response) {
      const state_abbrev = response.data?.state_abbrev;
      const time_zone = response.data?.time_zone;

      if (state_abbrev) {
        setValue('state_abbrev', state_abbrev);
      }
      if (time_zone) {
        setValue('time_zone', time_zone);
      }

      if (userDOB) {
        setValue('dob', userDOB);
      }
    }
  }, [isSuccess, response, setValue, userDOB]);

  const userTimeZone = watch('time_zone');
  const stateAbbreviation = watch('state_abbrev');
  const dob = watch('dob');

  const navigate = useNavigate();

  const {
    mutate: saveForm,
    isLoading: isSaving,
    isError,
  } = useMutation(saveTelehealthUserSession, {
    onSuccess: () => {
      if (redirectToHealthie) {
        setTimeout(() => {
          window.open(HEALTHIE_WHITE_LABEL_SSO_LINK, '_self');
        }, 1000);
      } else if (isDynamicIntakeFlowEnabled) {
        navigate(DYNAMIC_FORM_ROUTE);
      } else {
        navigate(DEMOGRAPHICS_FORM_ROUTE);
      }
    },
    onError: () => {
      if (redirectToHealthie) {
        navigate('/sso-error');
      }
    },
  });

  const shouldOpenShareResultsModal =
    !redirectToHealthie &&
    isShareResultsEnabled &&
    results_sharing_consent === false;

  const stateOptions: DropdownOptionType[] = Object.entries(
    stateNameToAbbreviation,
  ).map(([name, abbrev]) => ({
    text: name,
    value: abbrev,
    id: abbrev,
  }));

  const switchToStandardProgram = (program: string, planId: number | null) => {
    setValue('program', program);
    setSearchParams(
      {
        program,
        ...(planId && { planId: String(planId) }),
      },
      { replace: true },
    );
  };

  const redirectToHealthieOrShareResultsModal = useCallback(() => {
    const values = getValues();

    if (shouldOpenShareResultsModal) {
      setShareResultsModal(true);
    } else {
      saveForm({
        ...values,
        results_sharing_consent,
      });
    }
  }, [
    getValues,
    results_sharing_consent,
    saveForm,
    shouldOpenShareResultsModal,
  ]);

  const handleValidSubmit = React.useCallback(
    (values: FormValues) => {
      const allowedOnDemandStates = allowedStatesWithService?.states;
      const allowedOnDemandVerticals = allowedStatesWithService?.verticals;
      const isStateAllowed = allowedOnDemandStates?.includes(
        values.state_abbrev,
      );

      if (
        allowedOnDemandStates &&
        !isStateAllowed &&
        isProgramAllowed(program, allowedOnDemandVerticals)
      ) {
        setNoAreaServiceModal(true);
        return;
      }

      const allowedAsyncRxStates =
        authorizedStatesForService?.verticals[program]?.states;

      const isAsyncRxStateAllowed = allowedAsyncRxStates?.includes(
        values.state_abbrev,
      );

      const allowedAsyncRxVerticals =
        authorizedStatesForService?.verticals &&
        Object.keys(authorizedStatesForService.verticals);

      if (
        allowedAsyncRxStates &&
        !isAsyncRxStateAllowed &&
        isProgramAllowed(program, allowedAsyncRxVerticals)
      ) {
        setNoAsyncServiceModal(true);
        return;
      }
      redirectToHealthieOrShareResultsModal();
    },
    [
      program,
      redirectToHealthieOrShareResultsModal,
      allowedStatesWithService?.states,
      allowedStatesWithService?.verticals,
      authorizedStatesForService?.verticals,
    ],
  );

  const handleModalSubmit = (consent: boolean) => {
    setShareResultsModal(false);
    saveForm({
      state_abbrev: stateAbbreviation,
      time_zone: userTimeZone,
      program: program,
      results_sharing_consent: consent,
      dob,
    });
  };

  const handleContinueToSchedule = () => {
    setNoAreaServiceModal(false);

    const redirectProgram =
      allowedStatesWithService?.redirection[program] || FALLBACK_PROGRAM_SLUG;

    switchToStandardProgram(redirectProgram, userPlanId.planId);
    redirectToHealthieOrShareResultsModal();
  };

  const handleScheduleTelehealthVisit = () => {
    setNoAsyncServiceModal(false);

    const redirectProgram =
      authorizedStatesForService?.verticals[program]
        ?.program_redirection_slug || FALLBACK_PROGRAM_SLUG;

    switchToStandardProgram(redirectProgram, userPlanId.planId);
    redirectToHealthieOrShareResultsModal();
  };

  useEffect(() => {
    if (isSuccess && redirectToHealthie) {
      analytics.track({
        event: ANALYTICS.EVENTS.VIEWED_PAGE,
        data: {
          page: 'Healthie Redirect',
        },
      });

      handleSubmit(handleValidSubmit)();
    }
  }, [isSuccess, handleSubmit, handleValidSubmit, redirectToHealthie]);

  /**
   * Redirect to the store interstitial page if the feature flag
   * for the NEW results interstitial page is disabled.
   *
   * TODO: Remove this redirect once the feature is live and stable.
   */
  useEffect(() => {
    if (isResultsInterstitialDisabled) {
      const telehealthSessionUrl = appendQueryParamsToUrl(
        `${LEGACY_APP_ROOT}/telehealth/sessions/new`,
        {
          program,
          ...(!redirectToHealthie && { state_confirmation: 'true' }),
        },
      );

      window.open(telehealthSessionUrl, '_self');
    }
  }, [isResultsInterstitialDisabled, redirectToHealthie, program]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        programName: program,
      },
    });
  }, [program]);

  if (isResultsInterstitialDisabled) {
    return null;
  }

  if (isLoading) {
    return (
      <Layout>
        <InterstitialPageSkeleton />
      </Layout>
    );
  }

  if (redirectToHealthie) {
    return (
      <Layout>
        <S.Container>
          <Grid.Container
            as="form"
            spacing={['lg']}
            onSubmit={(e: React.FormEvent<HTMLDivElement>) => {
              e.preventDefault();
            }}
          >
            <Grid.Item
              width={[1]}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <S.Image src={ProviderImage} alt="" />
            </Grid.Item>
            <Grid.Item width={[1]}>
              <S.Heading>
                Redirecting to our appointment management tool...
              </S.Heading>
            </Grid.Item>

            <input type="hidden" name="state_abbrev" ref={register} />
            <input type="hidden" name="time_zone" ref={register} />
            <input type="hidden" name="program" ref={register} />
          </Grid.Container>
        </S.Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <S.Container>
        <FormProvider {...useFormMethods}>
          <Grid.Container
            as="form"
            spacing={['lg']}
            onSubmit={handleSubmit(handleValidSubmit)}
          >
            <Grid.Item
              width={[1]}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <S.Image src={ProviderImage} alt="" />
            </Grid.Item>
            <Grid.Item width={[1]}>
              <S.Heading>
                {isAsyncProgram ? ASYNC_HEADER_TEXT : HEADER_TEXT}
              </S.Heading>
            </Grid.Item>
            <Grid.Item width={[1]}>
              <S.Copy>This helps us connect you to the right providers.</S.Copy>
            </Grid.Item>

            <Grid.Item width={[1]}>
              <Dropdown
                id="state_abbrev"
                name="state_abbrev"
                label="State"
                items={stateOptions}
                placeholderText="Select a state"
                showErrorMessage
                ref={register({
                  required: 'Please select a state',
                })}
                error={errors.state_abbrev?.message}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setValue('time_zone', timeZone);
                }}
              />
            </Grid.Item>

            {shouldDisplayDOBField && (
              <DOBField
                id="dob"
                label="Date of Birth"
                variant="primary"
                modules={[]}
                sublabel={null}
                mod_type="dob"
                options_array={[]}
                custom_module_condition={null}
                required
              />
            )}
            <input type="hidden" name="time_zone" ref={register} />
            <input type="hidden" name="program" ref={register} />

            <Grid.Item width={[1]}>
              <S.Copy>
                If this is a medical emergency, dial 911 immediately.
              </S.Copy>
            </Grid.Item>
            <Grid.Item width={[1]}>
              <S.Button
                appearance="primary"
                type="submit"
                isLoading={isSaving}
                onClick={() => {
                  analytics.track({
                    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
                    data: {
                      transport: 'sendBeacon',
                      page: ANALYTICS.PAGES.TELEHEALTH_SESSIONS,
                      type: ANALYTICS.EVENTS.CLICKED_BUTTON,
                      label: ANALYTICS.LABELS.TELEHEALTH_STATE_SELECT_CTA,
                      ctaText: CTA_TEXT,
                      sectionHeader: HEADER_TEXT,
                      program,
                    },
                  });
                }}
              >
                {isAsyncProgram ? ASYNC_CTA_TEXT : CTA_TEXT}
              </S.Button>
            </Grid.Item>
            {isError && (
              <Grid.Item width={[1]}>
                <S.ErrorText>
                  Unable to submit the form. Please try again.
                </S.ErrorText>
              </Grid.Item>
            )}
          </Grid.Container>
        </FormProvider>
      </S.Container>
      <ShareResultsModal
        openModal={shareResultsModal}
        setOpenModal={setShareResultsModal}
        program={program || FALLBACK_PROGRAM_SLUG}
        handleConsentChoice={handleModalSubmit}
      />
      <NoAreaServiceModal
        program={program}
        openModal={noAreaServiceModal}
        setOpenModal={setNoAreaServiceModal}
        handleContinueToSchedule={handleContinueToSchedule}
      />
      <NoAsyncServiceModal
        program={program}
        openModal={showNoAsyncServiceModal}
        setOpenModal={setNoAsyncServiceModal}
        handleButtonClick={handleScheduleTelehealthVisit}
      />
    </Layout>
  );
}

export default InterstitialPage;
